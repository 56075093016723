.badge {
	display: inline-block;
	padding: 0.25em 0.5em;
	/* Adjust the padding as needed */
	font-size: 0.8rem;
	font-weight: 700;
	height: 1.2rem;
	width: 3rem;
	line-height: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 2vw;
	margin-top: 0.7rem;
}

.text-center {
	text-align: center;
}

.custom-header {
	font-size: 0.68rem;
	font-weight: 600;
	color: #5b5b5b;
	background: #f0f0f0;
}

.ag-right-aligned-header custom-header {
	font-size: 0.68rem;
	font-weight: 400;
	color: #5b5b5b;
	background: #f0f0f0;
}

.ag-header-cell-resize::after {
	background-color: #b4b4b4;
	width: 0.5px;
	height: 1.25rem;
}

.fund-query .ag-header-cell-resize::after {
	background-color: #b4b4b4;
	width: 0.5px;
	height: 1.25rem;
}

.ag-row {
	--ag-row-border-color: none;
	--ag-active-color: #651fff;
	--ag-header-cell-hover-background-color: #f0f0f0;
	--ag-wrapper-border-radius: 0px;
}

.ag-row:nth-child(even) {
	background-color: #fafafa;
}

.ag-theme-quartz {
	--ag-row-border-color: none;
	--ag-active-color: #651fff;
	--ag-header-cell-hover-background-color: #f0f0f0;
	--ag-wrapper-border-radius: 0px;
	width: 100%;
	max-width: 100%;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	border-radius: 0.25;
}
.ag-theme-quartz,
.ag-theme-quartz-auto-dark,
.ag-theme-quartz-dark {
	--ag-active-color: #651fff !important;
}

.ag-theme-quartz .ag-root-wrapper {
	border-radius: 0.25rem;
	overflow: hidden;
}

.ag-theme-quartz .ag-header,
.ag-theme-quartz .ag-body-viewport {
	border-radius: 0.25rem 0.25rem 0 0;
}

.ag-ltr .ag-cell {
	border-width: 0;
}

.action-item .ag-header-row {
	height: 30px;
	min-height: 30px;
	text-align: right;
	font-size: 11px;
}

.ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
	border-right: none !important;
}

.ag-label {
	white-space: nowrap;
	font-weight: 400;
	font-size: 13px;
}

.ag-pinned-left-header {
	border-right: none !important;
}

.ag-theme-quartz .ag-pinned-left-cols-container {
	border-left: none !important;
}

.wippillstyle {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 7px, 14px, 7px, 14px;
	height: 1.875rem;
	max-width: 5.813rem;
	border-radius: 0.75rem;
	border: 0.5px solid #651fff;
	color: #651fff;
	gap: 10px;
	background-color: #f9f7ff;
	font-weight: 700;
	font-size: 0.688rem;
	cursor: pointer;
	transition: background-color 0.3s ease, color 0.3s ease;
	position: relative;
	/* Required for pseudo-elements */
	margin-top: -0.2rem;
}

.wippillstyle-disable {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 7px, 14px, 7px, 14px;
	height: 1.875rem;
	max-width: 5.813rem;
	border-radius: 0.75rem;
	border: 0.8px solid gray;
	color: gray;
	background-color: #ffffff;
	font-weight: 700;
	font-size: 0.7rem;
	cursor: pointer;
	transition: background-color 0.3s ease, color 0.3s ease;
	position: relative;
	/* Required for pseudo-elements */
}

.wippillstyle:hover {
	background-color: #651fff;
	color: #ffffff;
}

.spendcashstyles {
	margin-top: -0.4rem;
	margin-bottom: -2.7rem;
	font-weight: 590;
	display: flex;
	justify-content: "right";
}

.secondcellintext {
	margin-top: -1.3rem;
	margin-left: 0.7rem;
}

.secondtextincell {
	margin-top: 1.125rem;
}

.secondtextincellfunds {
	font-weight: 400;
}

.firsttextincell {
	font-weight: 590;
	display: flex;
	justify-content: right;
	align-items: "center";
	padding-right: 0.813rem;
}

.ag-header {
	position: sticky;
	top: 0;
	z-index: 1;
	background-color: white;
	border-bottom: 1px solid #eeeeee;
}

.orderlabel {
	display: flex;
	width: 50%;
	min-width: 35rem;
	border: 1px solid lightgray;
}

.container {
	width: 82vw;
	display: flex;
	margin-bottom: -0.5rem;
}

.shareLabel {
	font-weight: 700;
	margin-left: 4.5rem;
	font-size: 1rem;
}

.shareflex {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.lowerLabel {
	margin-left: 4.8rem;
	color: grey;
}

.buylabel {
	display: flex;
	align-items: center;
	font-weight: 590;
	margin-left: 4rem;
}

.flextableheaders {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0.2rem;
	width: 94.7%;
}

.orderheader {
	margin-left: 0rem;
	/* Remove default margin on h1 */
}

.order-actions {
	display: flex;
	align-items: center;
}

.order-actions > * {
	margin-left: 1rem;
}

.aprrovebutton {
	color: white;
	max-height: 1.5rem;
}

.custom-header-style {
	background-color: #f0f0f0;
}

.ag-header-cell,
.ag-header-group-cell {
	background-color: #f0f0f0;
	padding-left: 1rem;
	padding-right: 1rem;
}

.ag-theme-quartz .ag-header-viewport {
	position: sticky;
	top: 0;
	z-index: 1;
	background-color: #f0f0f0;
}

.ag-ltr .ag-header-viewport .ag-header-cell-resize::after {
	background-color: #b4b4b4;
	width: 0.5px;
	height: 1.25rem;
}

.fund-query .ag-ltr .ag-header-viewport .ag-header-cell-resize::after {
	background-color: #b4b4b4;
	width: 0.5px;
	height: 1.25rem;
	margin-top: -0.3rem;
}
.fund-query .ag-pinned-left-header .ag-header-cell-resize::after {
	background-color: #b4b4b4;
	width: 0.5px;
	height: 1.25rem;
	margin-top: -0.3rem;
}

.action-item .ag-ltr .ag-header-viewport .ag-header-cell-resize::after {
	background-color: #b4b4b4;
	width: 0.5px;
	height: 1.25rem;
	margin-top: -0.3rem;
}
.action-item .ag-pinned-left-header .ag-header-cell-resize::after {
	background-color: #b4b4b4;
	width: 0.5px;
	height: 1.25rem;
	margin-top: -0.3rem;
}

.test {
	width: 82vw;
	min-width: 70rem;
	max-height: 23.125rem;
	min-height: 6.4rem;
	margin-top: 0.45rem;
}

.pen {
	height: 1rem;
	width: 1rem;
}

.approvebutton {
	height: 1.875rem;
	width: 9.188rem;
}

.ag-overlay-no-rows-center {
	margin-top: 3rem;
}

.action-item .ag-overlay-no-rows-center {
	margin-top: 2rem;
}

.boldfont {
	font-weight: 800;
}

.inBadge {
	height: 1rem;
	width: 2rem;
	font-size: 0.688rem;
	padding: 0px, 10px, 0px, 10px;
}

.outBadge {
	height: 1rem;
	width: 2.8125rem;
	font-size: 0.688rem;
	padding: 0px, 10px, 0px, 10px;
}

.ag-cell-focus,
.ag-cell-no-focus {
	border: none !important;
}

.no-border.ag-cell:focus {
	border: none !important;
	outline: none;
}

.ag-root-wrapper {
	border-radius: 0.25rem;
	margin-top: 12px;
	width: 100%;
}

.ag-theme-quartz .ag-layout-normal {
	width: 100%;
}

.right-aligned {
	text-align: right;
}

.ag-theme-quartz .ag-pinned-left-cols-container {
	background-color: #f8f9fa;
}

.order-tables.ag-theme-quartz .ag-pinned-left-header .ag-header-cell:nth-child(3) {
	border-right: 1px solid #ccc;
}

/* .ag-pinned-left-header .ag-header-cell:nth-child(2) .ag-header-cell-resize {
	display: none;
} */

.ag-pinned-left-header .ag-header-cell:nth-child(2):hover .ag-header-cell-resize {
	display: block;
}

.ag-theme-quartz .ag-pinned-left-cols-container .ag-row {
	border-right: 1px solid #ccc;
}

.holdingtab-main-container .ag-theme-quartz .ag-pinned-left-header .ag-header-cell:last-child {
	border-right: 1px solid #ccc;
}
.smart-query-table-section .ag-theme-quartz .ag-pinned-left-header .ag-header-cell:last-child {
	border-right: 1px solid #ccc;
}

/* .ag-theme-quartz .ag-pinned-left-header .ag-header-cell:last-child {
	border-right: 1px solid #ccc;
} */
.ag-theme-quartz .ag-body-horizontal-scroll {
	scrollbar-width: thin;
	scrollbar-color: rgba(98, 98, 98, 0.56) transparent;
}

.ag-theme-quartz .ag-body-horizontal-scroll::-webkit-scrollbar {
	height: 8px;
}

.ag-theme-quartz .ag-body-horizontal-scroll::-webkit-scrollbar-thumb:hover {
	background-color: rgba(230, 51, 51, 0.5);
}

.ag-theme-quartz .ag-body-horizontal-scroll::-webkit-scrollbar-track {
	background-color: tranrsparent;
}

.common-header-right .ag-header-cell-label {
	justify-content: right;
	font-size: 0.68rem;
	font-weight: 600;
	color: #5b5b5b;
	background: #f0f0f0;
}

.common-header-right.align-header-right {
	justify-content: flex-end !important;
	text-align: right !important;
}

.ag-header-cell-menu-button {
	margin-bottom: 4px;
	align-self: center;
}

.ag-header-cell {
	overflow: visible;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.ag-header-cell-label {
	position: relative;
	width: 100%;
}

.ag-header-cell-menu-button {
	position: absolute;
	top: 17px;
	right: 15%;
	transform: translateX(50%);
	z-index: 10;
}

.ag-header-cell:last-child .ag-header-cell-resize {
	display: none;
}

.action-item .ag-header-cell-menu-button {
	margin-top: -0.5rem;
}

.ag-pinned-left-header,
.ag-header-group-cell {
	background-color: #f0f0f0;
}

.spendCashCell {
	background-color: #ffb4b0;
}

.fund-query .ag-header-row {
	height: 30px;
	min-height: 30px;
	text-align: right;
	font-size: 11px;
	font-weight: 700;
	color: #5b5b5b;
}

.fund-query .ag-header-cell-menu-button {
	margin-top: -0.5rem;
}

.position-summary-table .ag-header-row {
	height: 30px;
	min-height: 30px;
	text-align: right;
	font-size: 11px;
}

.position-summary-table .ag-header-cell-menu-button {
	margin-top: -0.5rem;
}

.ag-ltr .ag-sort-indicator-icon {
	display: none;
}
.ag-popup {
	position: absolute;
	height: 100%;
}

/* AI - Assistant earningcalls table */
.earning-table-container .ag-header-row {
	height: 32px;
	font-size: 11px;
	font-weight: 700;
	color: #5b5b5b;
	border: none;
	background-color: white;
}

.earning-table-container .ag-header-cell {
	background-color: white;
}

.earning-table-container .ag-theme-quartz::after {
	content: none;
}

.earning-table-container .ag-root-wrapper {
	border: none;
}

.earning-table-container .ag-header-cell-label {
	justify-content: center;
}
