.carddiv {
	margin: 0 auto;
	padding: 0;
	width: 31rem;
	height: 35.188rem;
	/*max-width: 90%;*/
	padding: 2rem;
	border-radius: 2vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.8);
	box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.headtext {
	font-family: Futura;
	font-weight: 700;
	font-size: 3rem;
}

.errorgap {
	border-radius: 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 10vh;
	margin-bottom: 5vh;
	box-sizing: border-box;
	min-height: 15.625rem;
}

.userstyle {
	height: 3.5rem;
	width: 18.375rem;
	text-align: center;
	border: 1.5px solid darkgray;
	background: rgb(255 255 255 / 0%);
	border-radius: 1vw;
	font-weight: 400;
	font-size: 1.25rem;
	margin-top: 10vh;
}

.cashstyle {
	height: 3.5rem;
	width: 18.375rem;
	text-align: center;
	border: 1.5px solid darkgray;
	background: rgb(255 255 255 / 0%);
	border-radius: 1vw;
	font-weight: 400;
	font-size: 1.25rem;
	margin-top: 0.2rem;
}

.passwordstyle {
	height: 3.5rem;
	width: 18.375rem;
	text-align: center;
	border: 1.5px solid darkgray;
	margin-bottom: 0.938;
	border-radius: 1vw;
	background: rgb(255 255 255 / 0%);
	font-weight: 400;
	font-size: 1.25rem;
	margin-top: 2vh;
}

.loginbuttonstyle {
	width: 10.813rem;
	height: 3.5rem;
	border-radius: 1vw;
	color: white;
	margin-top: 2vh;
	font-weight: 700;
	font-size: 1.25rem;
}

.gridstyles {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-image: url("../../../public/assets/bg.jpg");
	overflow: hidden;
	margin: 0;
	padding: 0;
}
.errormessagestyle {
	height: 32px;
	width: 302.6px;
	margin: 15px 1px 14px 10px;
	font-size: 13px;
	line-height: 16px;
	color: #e64a19;
	display: flex;
	align-items: flex-end;
}

.grids {
	padding-left: 3rem;
	padding-right: 3rem;
	margin-top: 2.5rem;
}
