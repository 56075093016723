.create-fund .mantine-TextInput-label,
.create-fund .mantine-Select-label{
    font-size: 13px;
    line-height: 1.23;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 6px;
    font-weight: normal;
}

.create-fund .mantine-TextInput-input,
.create-fund .mantine-Select-input {
    height: 40px;
    font-size: 13px;
    text-align: left;
    color: #5b5b5b;
    background-color: #fff;
    border-radius: 4px;
    border: solid 0.5px #7d7d7d;
}
.input-div,.select-input{
    height: 79px;
    margin-bottom: 4px;
}

.half-width{
  width: 50%;
}

.create-fund .select-label {
    margin-bottom: 6px;
    color: rgba(0, 0, 0, 0.85);
}

.create-fund .mantine-Select-section {
    margin: 12px;
}

.create-fund .cancel-btn {
    width: 6.6875rem;
    height: 1.875rem;
    font-weight: 600;
    font-size: 0.8125rem;
    border-radius: 0.375;
    margin-left: 51px;
    margin-right: 16px;
    text-decoration: underline;
    background-color: transparent;
    color: rgba(0, 0, 0, 0.85);
}

.create-fund .create-btn {
    width: 6.6875rem;
    height: 1.875rem;
    font-weight: 600;
    font-size: 0.8125rem;
    border-radius: 0.375;
    box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.05), 0 0.5px 2.5px 0 rgba(0, 0, 0, 0.3);
    color: white;
    background-color: #651fff;
}
.disabled-input .mantine-TextInput-input{
    background-color: #eee;
}

.investment-search .mantine-Input-input {
    height: 1rem;
    width: 18.625rem;
    text-align: left;
    background-color: #fff;
    border-radius: 0.375rem;
    border: solid 0.5px #b4b4b4;
  }
  
  .investment-search .mantine-Select-input {
    height: 1rem;
    width: 18.625rem;
    text-align: left;
    background-color: #fff;
    border-radius: 0.375rem;
    border: solid 0.5px #b4b4b4;
  }
  
  .asset-input .mantine-Input-input {
    height: 2.5rem;
    width: 15.5rem;
    margin: 0.375rem 0 0;
    padding: 0.4375rem 0.4375rem 0.4375rem 0.875rem;
    border-radius: 4px;
    border: solid 1px #7d7d7d;
    background-color: #fff;
    text-align: left;
  }


  .auto-suggest-text-input .mantine-Input-input{
    padding: 0.6375rem 0.4375rem 0.4375rem 0.875rem;
  }
  
  .asset-input .mantine-Select-input {
    height: 2.5rem;
    width: 15.5rem;
    margin: 0.375rem 0 0;
    padding: 0.4375rem 0.4375rem 0.4375rem 0.875rem;
    border-radius: 0.25rem;
    border: solid 1px #7d7d7d;
    background-color: #fff;
    text-align: left;
  }
  
  .asset-input .mantine-Select-wrapper{
    width: 15.5rem;
  }
  
  .right-section-margin {
    padding-left: 2.9rem;
  }
  
  .button-shift-right-section{
    margin-left: 15rem;
    margin-top: 2.1rem;
  }
  
  .button-shift-left-section{
    margin-top: 2.1rem;
  }
  
  .error-message-fields{
    font-size: 11px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: left;
    color: #e64a19;
    margin-top: -0.8rem;
  }
  