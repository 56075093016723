.chat-app {
  justify-content: center;
  max-width: 60rem;
  width: 54rem;
  height: 93.8vh;
  font-family: Arial, sans-serif;
  padding: 2rem;
}

.landing-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 82%;
}

h1 {
  font-size: 2rem;
  color: #333;
}

.chat-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.chat-container {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  width: 100%;
}

.chat-bubble {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
}

/* .chat-bubble.user {
  justify-content: flex-end;
} */
.chat-bubble.question {
  justify-content: flex-end;
}

/* .chat-bubble.assistant {
  justify-content: flex-start;
} */
.chat-bubble.answer {
  justify-content: flex-start;
}

.logo {
  width: 40px;
  height: 40px;
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  font-weight: bold;
}

.message {
  color: #333;
  padding: 0.5rem 1rem;
  border-radius: 15px;
  max-width: 90%;
  word-wrap: break-word;
  margin-top: -1rem;
}

/* .chat-bubble.user .message {
  background-color: #f7f9f9;
  color: rgba(0, 0, 0, 0.85);
  margin-left: 0.5rem;
} */
.chat-bubble.question .message {
  background-color: #f7f9f9;
  color: rgba(0, 0, 0, 0.85);
  margin-left: 0.5rem;
}

.text-input {
  width: 768px;
  height: 96px;
  margin: 56px 0 0 0px;
  padding: 8px 16px 0px 16px;
  border-radius: 30px;
  background-color: #f7f9f9;
}

.text-input input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 1rem;
}

.icon {
  cursor: pointer;
  font-size: 1.5rem;
  color: #007bff;
}

.attach-icon {
  margin-left: 0.5rem;
}

.send-icon {
  margin-right: 0.5rem;
}

.chat-input .mantine-TextInput-input {
  width: 44.8rem;
  background-color: #f7f9f9;
  text-align: left;
  margin-bottom: 0.9rem;
  border: none;
  padding-left: 0.5rem;
}

.chat-input .mantine-TextInput-input::placeholder {
  color: #5b5b5b;
}

.landing-screen.hidden,
.chat-screen.hidden {
  display: none;
}

.landing-screen.visible,
.chat-screen.visible {
  display: flex;
}

.chat-scroll-screen {
  margin-top: -3rem;
  margin-left: 2rem;
}
