.navtext {
	font-weight: 700;
	font-size: 0.68rem;
	margin-bottom: 0.3rem;
	margin-left: 0.25rem;
}

.navtext2 {
	font-weight: 700;
	font-size: 0.68rem;
	margin-top: "1vh";
	margin-right: 1rem;
}

.tables-nav {
	margin-left: 0.65rem;
	margin-right: 10px;
}

.username {
	display: flex;
	align-items: center;
	margin-left: 1rem;
	font-weight: 700;
	font-size: 0.69rem;
	padding-left: 0.3rem;
}

.paper {
	overflow: hidden;
	min-width: 202px;
	height: 100%;
}

.textupper {
	font-family: Futura;
	font-weight: 700;
	font-size: 0.875rem;
	padding-left: 1rem;
	line-height: 2rem;
	height: 2rem;
	margin-top: 0.5rem;
}

.searchbar {
	margin: 1rem;
	width: calc(100% - 32px);
	margin-top: 2vh;
	margin-bottom: 2vh;
}

.iconcircle {
	margin-right: 1rem;
	margin-top: 1.5rem;
}

.iconlist {
	margin-right: 1rem;
	margin-top: 0.5rem;
}

.inputnavbar {
	display: flex;
	justify-content: center;
	margin-top: 1rem;
}

.layout {
	display: flex;
	flex-direction: row;
	margin-top: 1.5vh;
}

.layoutMoo {
	display: flex;
	flex-direction: row;
}

.linkNoUnderline {
	text-decoration: none;
	display: flex;
	flex-direction: row;
	margin-top: 1rem;
}

.flexContainer {
	display: flex;
	align-items: center;
	width: 12.5rem;
}

.totalPillContainer {
	margin-right: 10px;
}

.totalPillContent {
	display: flex;
	align-items: center;
}

.layout {
	display: flex;
	justify-content: space-between;
}

.totalPillmarketonopen {
	margin-right: 1.82rem;
	margin-bottom: 0.3rem;
}

.totalPillmarket {
	margin-right: 1.82rem;
	margin-bottom: 0.3rem;
}

.totalPillmarketonclose {
	margin-right: 1.17rem;
	margin-bottom: 0.3rem;
}

.totalPillmarketfuture {
	margin-right: 1.17rem;
	margin-bottom: 0.3rem;
}

.icons {
	margin-left: var(--mantine-spacing-md);
	margin-top: 0.3rem;
	size: 20;
	font-weight: 700;
}

.usertext {
	font-size: 0.688rem;
	font-weight: 700;
	margin-top: 1rem;
	margin-left: 1rem;
	margin-right: -0.1rem;
}

.settingtext {
	font-size: 0.688rem;
	font-weight: 700;
	margin-top: 1rem;
	margin-left: 0.9rem;
	margin-right: -0.1rem;
}

.fundlisttext {
	font-weight: 700;
	margin-top: 0.3rem;
	margin-right: 0.1rem;
}

.allfundtext {
	font-weight: 700;
	font-size: 0.688rem;
}

.iconStyle {
	margin-left: var(--mantine-spacing-md);
	height: 0.875rem;
	width: 1rem;
}

.readymargin {
	margin-left: 4.4rem;
}

.notreadymargin {
	margin-left: 2.9rem;
}

.completedmargin {
	margin-left: 2.7rem;
}

.rowContainer {
	display: flex;
	align-items: center;
	width: 11.75rem;
}

.rownamestyle {
	margin-left: 0.6rem;
	font-size: 0.8rem;
	font-weight: 400;
}

.rightAlign {
	margin-left: auto;
}

.singlebutton {
	color: black;
}

.dashboardpill {
	margin-top: 0.3rem;
}

.gap {
	margin-top: 0.1rem;
}

.order-gap {
	margin-top: 11px;
}
.dashstyle {
	margin: 0.35rem 0.45rem 0 0.45rem;
	border-radius: 0.3rem;
	padding: 0.1rem 0.3rem 0.3rem 0.4rem;
	text-align: center;
}

.liststyle {
	margin: 0.35rem 0.45rem 0 0.45rem;
	border-radius: 0.3rem;
	padding: 0.25rem 0.3rem 0.15rem 0.35rem;
	text-align: center;
}

.allfundstyle {
	margin-right: 0.7rem;
	padding-top: 0.3rem;
	padding-bottom: 0.3rem;
	border-radius: 0.3rem;
}

.allfund {
	margin-right: 1rem;
}

.coacstyle {
	margin-right: 0.7rem;
	padding-top: 0.3rem;
	padding-bottom: 0.3rem;
	border-radius: 0.3rem;
}

.coac {
	margin-right: 0.9rem;
	margin-left: 0.08rem;
}

.dashboard {
	margin-right: 1rem;
	margin-left: 0.18rem;
	margin-top: 0.35rem;
}

.allfundquerylabel {
	font-weight: 700;
	size: 0.7rem;
}

.userimg {
	margin-top: 1rem;
	margin-left: -0.35rem;
	color: grey;
}

.img {
	height: 1rem;
	width: 0.125rem;
}

.renderselect {
	padding: 0.18rem;
	margin-right: 0.8rem;
	margin-top: 0.1rem;
	border-radius: 0.3rem;
	padding-right: 0.5rem;
}

.left-nav-header {
	height: 3.45rem;
	display: flex;
	align-items: center;
	margin-left: 1.1rem;
	position: sticky;
	margin-top: -4px;
	z-index: 10;
}

.allfundstatus {
	margin-top: 2.2vh;
	margin-bottom: -0.5rem;
}

.inputnavbar .mantine-Input-input {
	text-align: left;
	border: 1px solid #0000000d;
	background-color: #eeeeee;
	font-size: 0.8125rem;
	font-weight: 400;
}

.labelgap {
	margin-left: -0.35rem;
}

.labelgapfundstatus {
	margin-left: -0.36rem;
	margin-top: 1.5rem;
	margin-bottom: 2rem;
}

.renderstyle {
	margin-right: -0.13rem;
}

.iconStyle-card {
	height: 2.5rem;
	width: 2.5rem;
}

.action-icon {
	margin-right: 0.7rem;
}

.bullet-image {
	margin-top: 1.3rem;
	margin-left: 0.5rem;
}

.allfundqueryicon {
	height: 1rem;
	width: 1.375rem;
}

.folder-list {
	font-family: "Roboto", sans-serif;
}

.folder-item {
	display: flex;
	align-items: center;
	padding: 4px 0;
	cursor: pointer;
	font-size: 14px;
}

.folder-item .folder-icon {
	margin-right: 8px;
	font-size: 16px;
	color: #4a4a4a;
}

.folder-item .folder-text {
	flex-grow: 1;
	font-size: 14px;
	color: #4a4a4a;
}

.folder-item .chevron-icon {
	margin-left: auto;
	font-size: 16px;
	color: #4a4a4a;
	transition: transform 0.2s ease-in-out;
}

.folder-item.expanded .chevron-icon {
	transform: rotate(90deg);
}

.favorites-icon {
	color: #ffd700;
	margin-right: 8px;
	font-size: 16px;
}

.child-item {
	margin-left: 24px;
	font-size: 14px;
	color: #4a4a4a;
	cursor: pointer;
}

.arrow {
	z-index: 999;
}

.usernameTer {
	display: flex;
	align-items: center;
	margin-left: 0.68rem;
	font-weight: 700;
	font-size: 0.69rem;
	min-width: 180px;
	text-align: center;
}

.scroll-bar {
	overflow-y: auto;
	transition: border-top 0.3s ease;
	padding-bottom: 3rem;
	max-height: calc(100vh - 3.45rem);
	margin-top: 5px;
}
@media (max-height: 460px) {
	.scroll-bar {
		border-top: 1px solid lightgrey;
		padding-bottom: 4rem;
	}
}
@media (max-height: 750px) {
	.scroll-bar {
		border-top: 1px solid lightgrey;
		padding-bottom: 6rem;
	}
}

.account-user-icon {
	height: 2rem;
	width: 2rem;
}

.setting-navlink {
	margin-left: -0.6rem;
}

.copy-template {
	height: 1.5rem;
	width: 1.5rem;
}

.login-logo {
	height: 2.5rem;
}

.small-dropdown {
	margin-top: 0.5rem;
}
.section-labels {
	display: flex;
	flex-direction: column;
}

.label-row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 4px 0px 4px 6px;
	border-radius: 4px;
	transition: background-color 0.2s ease;
	width: 100%;
	margin-bottom: 4px;
}
.section .label-row {
	margin-left: 18px;
	height: 27px;
	padding: 4px 0px 4px 3px;
	margin-bottom: 0px;
}

.clicked-row {
	background-color: rgba(64, 64, 64, 0.11);
	border-radius: 4px;
}

.label-link {
	text-decoration: none;
	width: 100%;
	display: flex;
	align-items: center;
}

.label-content {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.label-icon {
	flex-shrink: 0;
	margin: 2px 12px 1px 3px;
}

.label-text {
	flex-grow: 1;
	font-weight: 500;
	font-size: 13px;
	color: rgba(0, 0, 0, 0.85);
}

.label-badge {
	margin-left: auto;
	font-weight: 700;
	color: var(--mantine-color-gray-7);
}
.section {
	margin-bottom: 25px;
}
.section-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 8px;
	width: 100%;
	color: #7d7d7d;
}
.section-header-link {
	display: flex;
	align-items: center;
	font-size: 0.875rem;
	font-weight: 500;
	color: rgba(0, 0, 0, 0.85);
	text-decoration: none;
	border-radius: 4px;
	width: 100%;
	padding-right: 10px;
}

.header-count-badge,
.section-count-badge {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 11px;
	font-weight: 600;
	line-height: 1;
	text-align: center;
	width: auto;
	min-width: 18px;
	height: 18px;
	padding: 0 4px;
	border-radius: 9px;
	color: #651fff;
	background-color: rgba(0, 0, 0, 0.05);
}
.section-count-badge {
	margin-right: 20px;
}
.header-count-badge {
	margin-top: 2px !important;
}

.section-header .header-count-badge {
	margin: 0 2px;
}
.nav-container {
	padding: 0;
	height: 100vh;
}

.ai-logo {
	height: 1.875rem;
	width: 1.875rem;
	margin-right: 1.5rem;
	margin-top: 0.5rem;
}

.no-icon {
	opacity: 0;
}
.tables-nav .section .label-text {
	margin-left: -7px;
}

.progress-ring {
	height: 1.25rem;
	width: 1.25rem;
	margin-top: 0.5rem;
}
.sign-out{
	margin-top: 2rem;
}
.nav-section-mt{
	margin-top: 1rem;
}