html,
body,
#root {
	height: 100vh;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
}

body {
	margin: 0;
	/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
	font-family: "SF Pro Display", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

main {
	overflow-y: auto;
}

.main-content {
	padding: 1.875rem;
}

.main-content-analysis {
	padding-top: 0.9rem;
}

/* .margin-segment {
	padding-top: 0.3rem;
} */

.border-around {
	border: 1px solid gray;
}

a {
	text-decoration: none;
}

.box {
	height: 1.25rem;
	align-items: center;
	justify-content: center;
	display: flex;
	font-size: 0.688rem;
}

.box.lightPurple {
	background-color: #eee7fc;
}

.box.midPurple {
	background-color: #ded0ff;
}

.box.purple {
	background-color: #651fff;
	color: #fff;
}

.counters-grid .bar-label {
	color: #5b5b5b;
	font-size: 0.688rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1rem;
	margin: 0 0.375rem;
	width: 3.813rem;
	word-break: break-all;
}

.counters-grid .bar-tooltip {
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.btn-underline {
	padding: 0.5rem;
	text-decoration: underline;
	color: #5b5b5b;
	font-size: 0.813rem;
	line-height: 1rem;
	font-weight: 500;
	background-color: transparent;
	border: none;
}

.tab-labels {
	padding: 0rem 0.625rem 0.625rem 0.625rem;
	cursor: pointer;
	height: 1.5rem;
	border: 1px solid lightgrey;
	text-align: center;
}

::-webkit-scrollbar-thumb {
	opacity: 0.1;
}

.btn-underline {
	text-decoration: underline;
}

.form-textarea {
	border: none;
	background: #fafafa;
	padding: 0.5rem;
	margin-bottom: 0.5rem;
}

.btn-shadow {
	box-shadow: 0px 0px 0px 0.5px rgba(0, 0, 0, 0.13), 0px 0.5px 2.5px 0px rgba(0, 0, 0, 0.3);
}

.dashboard-top {
	margin-top: 1.875rem;
	height: 100%;
}

.labelInText {
	border-radius: 0.625rem;
	font-size: 0.688rem;
	font-weight: 700;
	width: 2rem;
	height: 1rem;
	padding: 0px, 10px, 0px, 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.labelOutText {
	border-radius: 0.625rem;
	font-size: 0.688rem;
	font-weight: 700;
	width: 2.813rem;
	height: 1rem;
	padding: 0px, 10px, 0px, 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.preset-search .mantine-Input-input {
	background-color: white;
	text-align: left;
	display: flex;
	justify-content: center;
	margin-top: 0.5rem;
	font-size: 0.68rem;
}

.create-folder .mantine-Input-input {
	background-color: white;
	text-align: left;
	display: flex;
	justify-content: center;
	font-size: 0.68rem;
}

.main-content-users {
	padding: 1.575rem;
	min-width: 77.5rem;
	margin-top: -2rem;
}

/* .main-content-all-fund-status {
	padding-top: 1.8rem;
	padding-left: 1.8rem;
} */

.custom-message {
	position: absolute;
	bottom: 0;
	margin-bottom: 0.8rem;
}

.mantine-Button-root {
	border-radius: 0.375rem;
}

.ag-root .mantine-Button-root {
	border-radius: 0.625rem;
}

.inputnavbar .mantine-TextInput-root {
	border-radius: 0.5rem;
}

.dashboard-top .mantine-Card-root {
	border-radius: 1.125rem;
}

.mantine-TextInput-input:focus,
.mantine-Select-input:focus,
.inputnavbar .mantine-Input-input:focus {
	border: 1px solid #5b5b5b;
}

.input-border:focus {
	outline: none !important;
}

.fund-query::-webkit-scrollbar {
	width: 12px;
	height: 12px;
}

.fund-query::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.3);
	border-radius: 10px;
}

.fund-query::-webkit-scrollbar-thumb:hover {
	background-color: rgba(0, 0, 0, 0.5);
}

.fund-query::-webkit-scrollbar-track {
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 10px;
}

.fund-query {
	scrollbar-width: thin;
	scrollbar-color: rgba(0, 0, 0, 0.3) rgba(255, 255, 255, 0.1);
}

.fund-query {
	-ms-overflow-style: -ms-autohiding-scrollbar;
}

:root {
	--badge-height: 3rem;
}

.ag-theme-quartz .ag-ltr input[class^="ag-"][type="number"] {
	border-left: 0.5px solid #7d7d7d;
	border-right: 0.5px solid #7d7d7d;
	border-top: 2px solid #7d7d7d;
	border-bottom: 2px solid #7d7d7d;
}

.disabled-row {
	background-color: #f0f0f0;
	/* Light gray background */
	color: #a0a0a0;
	/* Light gray text */
	pointer-events: none;
	/* Disable pointer events */
}

.vertical-line {
	display: inline-block;
	border-left: 1.5px solid #ccc;
	margin: 0 10px;
	height: 1rem;
}

.mantine-Tabs-list::before {
	border-color: transparent !important;
}

.right-align-header .ag-header-cell-label {
	text-align: right;
	justify-content: right;
}

.right-align-header {
	text-align: right;
}

.delete-modal .mantine-Modal-body {
	padding: 0;
}

.asset-dropzone {
	height: 4.0625rem;
	margin: 2.125rem 0 2.0625rem;
	padding: 1.4375rem 7.25rem 1.625rem 7.1875rem;
	border-radius: 0.625rem;
	border: solid 0.5px #b4b4b4;
	background-color: #fff;
}

.import-modal .mantine-Modal-body {
	width: 29.375rem;
	height: 15.75rem;
	padding-top: 0.8rem;
	border-radius: 30px;
	box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.05), 0 0.5px 2.5px 0 rgba(0, 0, 0, 0.3);
	background-color: #f7f9f9;
}

.parent-container {
	display: flex;
	flex-direction: row;
	height: 100vh;
}

[data-portal="true"]:empty {
	display: none;
}

.table-container {
	min-height: 3.8rem;
	position: relative;
}

.ag-body-horizontal-scroll-viewport {
	z-index: 1;
}

.ag-theme-quartz {
	position: relative;
}

.ag-theme-quartz::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 0.7rem;
	background-color: #f0f0f0;
	border: solid 0.5px lightgrey;
	border-top: none;
	border-radius: 0 0 4px 4px;
}

.vertical-scroll-table {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	width: 0.7rem;
	height: 100%;
	background-color: #f0f0f0;
	border: solid 1px lightgrey;
	border-left: none;
	border-radius: 0px 4px 4px 0px;
	z-index: 1;
}

.custom-header-left-border {
	border-left: 0.5px solid lightgrey;
}

.hint-options {
	position: absolute;
	top: 4rem;
	left: 0;
	width: 15.5rem;
	border: 1px solid #ccc;
	border-radius: 0.25rem;
	background-color: #fff;
	list-style: none;
	margin: 0;
	padding: 0;
	z-index: 1000;
	max-height: 150px;
	overflow-y: auto;
}

.hint-input {
	position: absolute;
	top: 2.25rem;
	left: 0.9375rem;
	color: gray;
	opacity: 0.5;
	pointer-events: none;
}

.option-color {
	padding: 0.5rem;
	cursor: pointer;
}

.mantine-TextInput-error {
	font-weight: 600;
	color: #e64a19;
	font-size: 0.6875rem;
}

/* Align cell values */
.ag-cell-align-left {
	text-align: left;
}

.ag-cell-align-right {
	text-align: right;
}

.ag-cell-align-center {
	text-align: center;
}

.right-aligned .ag-header-cell-label {
	justify-content: right;
}

.center-aligned .ag-header-cell-label {
	justify-content: center;
}

.left-aligned .ag-header-cell-label {
	justify-content: left;
}
